import axios from 'axios'
import React, { useState, useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import {default as Symbol} from "../../images/symbol.png"
import { Link } from 'gatsby';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

export const Drazby = () => {

  const [drazby, setDrazby] = useState([]);

  useEffect(() => {
    async function getData() {
      try {
          const res = await axios.post('https://www.exekucepraha.cz/?e=drazby'); // Fetch the resource 
          setDrazby(res.data); 
      // Do your JSON handling here
      } catch(err) {
          console.log(err);
          // This probably means your response is text, do you text handling here
      } 
    }
    getData()
    // eslint-disable-next-line
  }, [])
  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
      <div className="  px-5 z-50">  
        <button onClick={() => goToSlide(currentSlide - 1)}><ChevronLeftIcon className="w-7 opacity-50 z-50 absolute bottom-0 left-5 text-gray-500" /></button>
        <button onClick={() => goToSlide(currentSlide + 1)}><ChevronRightIcon className="w-7 opacity-50 z-50 absolute bottom-0 right-5 text-gray-500" /></button>
      </div>
    );
  };
 

  return (
     <div className="  mx-auto">
       
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={true}
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={4000}
        keyBoardControl={true}
        arrows={false}
        customButtonGroup={<ButtonGroup />}
        containerClass="pb-7" 
        customTransition="transform 500ms ease-in-out"
        transitionDuration={700}  
        itemClass="transition delay-150 duration-300 ease-in-out " 
      >
        {drazby.map((drazba,drazbaIdx) => ( 
          <Link key={drazbaIdx} to={'/drazby/exekuce/' + drazba.id}>
          <div  className="relative text-sm bg-white m-3 transition group  duration-200 ease-in-out border  border-gray-300 hover:border-gray-600 ">
            <div className=" bg-gradient-to-t from-black to-transparent  h-36 w-full absolute bottom-0 z-10" />
            <div className='relative' >
               {drazba.img ?
                <img className="object-cover h-60 w-full  " alt={drazba.name} src={drazba.img} />
                : 
                <img className="object-cover h-60  w-full  " alt={drazba.name} src={Symbol} />
               }
            </div>
            <div className='relative z-20'>

                <div className="px-4  py-3 font-medium  text-gray-100 pb-8 h-20  grid absolute bottom-0 rounded-md  " >
                <p className=" line-clamp-2 overflow-hidden leading-tight ">{drazba.name}</p>
                
                </div>
                <div className=" px-6 py-5 pb-2 text-gray-100 text-xs flex  absolute bottom-0 right-0" >

                    <svg className="w-4 mx-3 opacity-50 " fill="currentColor" id="Capa_1"  viewBox="0 0 512 512"  xmlns="http://www.w3.org/2000/svg"><g><path d="m255.99 421.75h241c8.284 0 15 6.716 15 15v60.25c0 8.284-6.716 15-15 15h-241c-8.284 0-15-6.716-15-15v-60.25c0-8.284 6.715-15 15-15z"/><g><path d="m13.206 387.822 159.895-159.895 63.816 63.816-159.894 159.895c-8.797 8.797-20.353 13.196-31.908 13.196s-23.111-4.398-31.908-13.196c-17.595-17.594-17.595-46.222-.001-63.816z"/><path d="m204.182 110.037h150.5v150.75h-150.5z" transform="matrix(.707 -.707 .707 .707 -49.263 251.895)"/><path d="m439.061 196.019 21.39 21.39c5.858 5.858 5.858 15.355 0 21.213l-127.81 127.81c-2.929 2.929-6.768 4.394-10.606 4.394s-7.678-1.464-10.606-4.394l-21.39-21.39c-5.858-5.858-5.858-15.355 0-21.213l127.809-127.809c5.858-5.859 15.355-5.859 21.213-.001z"/><path d="m141.016 174.806c-5.858 5.858-15.355 5.858-21.213 0l-21.39-21.39c-5.858-5.858-5.858-15.355 0-21.213l127.809-127.81c5.856-5.857 15.354-5.858 21.213 0l21.39 21.39c5.858 5.858 5.858 15.355 0 21.213z"/></g></g></svg>
                    {drazba.datum}
                </div>
            </div>
          </div> 
          </Link>
        ))}
       
      </Carousel>
      </div>
  );
}
