import { ApolloProvider } from "@apollo/client";
import React from "react";
import { apolloClient  } from "../../client/apollo";
import ArticleList from "./ArticleList"; 

export const Articles = ({ cat, limit}) => {


    return (
    <ApolloProvider client={apolloClient}> 
        <ArticleList
            cat={cat} limit={limit}
        /> 
    </ApolloProvider>)
}

export default Articles;