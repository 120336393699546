import React  from "react";  
import { useQuery } from "@apollo/client";
import { ARTICLES_LIST } from "../../query/articles";  
import { Link } from "gatsby";

export const ArticleList = ({cat, limit }) => {
 

    let queryName = ARTICLES_LIST; 
 
    const {data} = useQuery(queryName, {
        variables: {
            cat: cat ?? null,
            limit: limit,
        }
    });
 
    let items = data?.articleFindAll || [];
  
    return (
        <> 
          

            <div className={" w-full text-gray-800 pt-5 "  }>
               
  
                    
                <div className="mt-6 grid sm:grid-cols-2 gap-y-8 gap-x-6 lg:grid-cols-3  xl:gap-x-8 max-w-5xl mx-auto  px-5">
                    
                    {items && items.length > 0 && items.map((item,itemIdx) => (
                        <Link to={'/media/' + item.alias} key={itemIdx} className="bg-amber-700 bg-opacity-10 hover:bg-opacity-5 p-5 space-y-6">
                            <div className='relative' >
                                {item.img ?
                                    <img className="object-cover h-60 w-full  " alt={item.name} src={item.img} />
                                    : 
                                    null
                                }
                            </div>
                            <div className="text-xl font-semibold">
                                {item.name}
                            </div>
                            <div dangerouslySetInnerHTML={{__html: item.prologue}} className="text-sm line-clamp-5">
                            
                            </div>
                        </Link>
                    ))}

                </div>

            </div>
                   
        </>
    )
}

export default ArticleList;