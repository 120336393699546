import React from "react"
import Layout from "../components/layout/Layout";
import { Helmet } from "react-helmet";
import { Drazby } from "../components/elements/Drazby";
import { Link } from "gatsby";
import {default as Opravneny} from "../images/razitko.svg"
import {default as Povinny} from "../images/soudnirazitko.svg"
import {default as Dalsi} from "../images/sluzby.svg"
import Articles from "../components/elements/Articles";

const IndexPage = ({pageContext, location}) => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Spolehlivý partner pro exekuční vymáhání pohledávek | Exekutorský úřad Praha 9</title> 
    </Helmet>
    <Layout location={location} > 
        <div className="mx-auto max-w-6xl py-10 my-5"> 
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-14 lg:gap-6">
                <div className="text-center px-5">
                    <img src={Opravneny} alt="Pro oprávněné" className="w-14 h-14 mx-auto" />
                    <Link to={'/pro-opravnene/'} className="block my-3 text-xl font-bold">
                        Pro oprávněné
                    </Link>
                    <div className="text-sm">
                        Věřitel, který vlastní pravomocný a vykonatelný exekuční titul, na jehož základě má vymahatelnou pohledávku vůči povinnému.
                        <Link to={'/pro-opravnene/'} className="text-sky-800 block mt-2 hover:underline font-semibold">Jsem věřitel</Link>
                    </div>
                </div> 
                <div className="text-center px-5">
                    <img src={Povinny} alt="Pro povinné" className="w-14 h-14 mx-auto" />
                    <Link  to={'/jak-postupovat/'} className="block my-3 text-xl font-bold">
                        Pro povinné
                    </Link>
                    <div className="text-sm">
                        Povinným v exekučním řízení se dlužník stává na základě pověření exekučního soudu soudnímu exekutorovi k provedení exekuce.
                        <Link to={'/jak-postupovat/'} className="text-sky-800 block mt-2 hover:underline font-semibold">Jsem dlužník</Link>
                    </div>
                </div> 
                <div className="text-center px-5">
                    <img src={Dalsi} alt="Další služby" className="w-14 h-14 mx-auto" />
                    <Link to={'/dalsi-cinnost/'} className="block my-3 text-xl font-bold">
                        Další služby
                    </Link>
                    <div className="text-sm">
                        Poskytujeme služby jako například exekutorské zápisy, exekutorskou úschovu, právní pomoc, dobrovolné dražby a další.
                        <Link to={'/dalsi-cinnost/'} className="text-sky-800 block mt-2 hover:underline font-semibold">Další služby</Link>
                    </div>
                </div> 
            </div>
        </div>
            <hr className="max-w-5xl mx-auto "  />
            <div className="bg-gray-100">

                <div className="mx-auto max-w-6xl py-10 ">
                    <div className="py-5 text-3xl text-center">
                        Aktuální dražby
                    </div>
                    <Drazby />
                </div>

            </div>

            <div >

                <div className="mx-auto max-w-6xl py-10 px-5 ">
                    <div className="py-5 text-3xl text-center">
                       Média a zprávy
                    </div>
                    <div className="py-5   text-center text-sm max-w-4xl mx-auto">
                    Naleznete zde vyjádření k aktuálním článkům z médií, které se týkají naší činnosti, odkazy na zajímavé postřehy a názory. 
                    Dále informace o akcích, které Exekutorský úřad pořádá nebo se na nich podílí, případně zajímavosti z činnosti našeho úřadu, práci našich spolupracovníků či soudního exekutora.
                    </div>
                    <Articles cat={'media'} limit={3} />
                </div>

            </div>
    </Layout>
  </>
)

export default IndexPage
